import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminNav from './AdminNav';
import './adminTop.css';
import { RootState } from '../../redax/reducers/rootReducer';
import Loader from '../loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchRegionsRequest } from "../../redax/actions/regionsActions/regionsActions";
import { fetchAdminApplicationsListRequest } from '../../redax/actions/adminApplicationsListActions/adminApplicationsListActions';

const AdminApplications = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const applicationsList = useSelector((state: RootState) => state.adminApplicationsList);
  const { regions  } = useSelector( (state: RootState) => state.regions);
  const [loadScreen, setLoadScreen] = useState(true);
  
  const [current_page, setCurrentPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [total_count, setTotalCount] = useState<any>(1);
  const [applicationList, setApplicationList] = useState<any>([]);
  const [applicationStatuses, setApplicationStatuses] = useState<any>([]);
  const [keyword, setKeyword] = useState(''); 
  const [page, setPage] = useState(1);
  const [downCsvPath, setDownCsvPath] = useState('');
  const navigate = useNavigate();
  const [statusId, setStatusId] = useState<number | ''>('');
  
  useEffect(() => {
    getApplications(1,'','');
    dispatch(fetchRegionsRequest());
}, [dispatch]); 

const getApplications = (page: any, keyword: any, statusId: any) => {
    setLoadScreen(true);
    const data = {
        "page": page,
        "keyword": keyword ? keyword : '',
        "status_id": statusId ? statusId : ''
    }
        dispatch(fetchAdminApplicationsListRequest(data));
        if(applicationsList.pagenation !== undefined) {
            setCurrentPage(applicationsList.pagenation.current_page);
            setTotalPages(applicationsList.pagenation.total_pages);
          }
}


  useEffect(() => {
    if (applicationsList.pending) {
        setApplicationList(applicationsList.applications);
        setCurrentPage(applicationsList.pagenation.current_page);
        setTotalPages(applicationsList.pagenation.total_pages);
        setTotalCount(applicationsList.pagenation?.total_count || 1);
        setLoadScreen(false)
    }
  }, [applicationsList]);


  useEffect(() => {
    if (regions) {
        setApplicationStatuses(regions.application_statuses)
    }
  }, [regions]);

  const shaping_pagenation = () => {
    if (total_pages === null) return [1]; // Add a check for total_pages being null
    if (total_pages === 1) return [1]; // Add a check for total_pages being null
    
    if (total_pages <= 11) {
      return Array.from({ length: total_pages }, (_, index) => index + 1);
    } else if (current_page < 7) {
      return Array.from({ length: 11 }, (_, index) => index + 1);
    } else if (total_pages - current_page <= 5) {
      const diff_current_total = total_pages - current_page;
      const pagenation_start = current_page - (11 - (diff_current_total + 1));
      const pagenation_end = current_page + diff_current_total;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    } else {
      const pagenation_start = current_page - 5;
      const pagenation_end = current_page + 5;
      return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
    }
};




const pageDecrement = () => {
    if (page <= 1) {
        setPage(1);
        return;
    }
    setPage((prevPage) => prevPage - 1);
    setLoadScreen(true);
    getApplications(page-1,keyword,'')
}


const updatePage = (p: any) => {
    setPage(p);
    setLoadScreen(true);
    getApplications(p,keyword,'')
}


  const pageIncrement = () => {
    if (page >= total_pages) {
      setPage(total_pages);
      return;
    }
    setPage((prevPage) => prevPage + 1);
    setLoadScreen(true);
    getApplications(page+1,keyword,'')
  }


  function handleClick() {
    getApplications(1,'',statusId)
}
  
  return (
    <main id='adminJobList'>
      <AdminNav />
      {loadScreen ? <Loader></Loader> : <></>}
      <section>
        <div className="adminPageWrapper">
          <div className="header">
            <h2 className="title">応募一覧 </h2>
            <div className="inputBox">
                <select 
                    name="search2" 
                    value={statusId} 
                    onChange={(e) => setStatusId(Number(e.target.value) || '')}
                    className='typeSelect'
                >
                    <option value='' >全体</option>
                    {applicationStatuses && applicationStatuses.map((status:any) => (
                    <option key={status.id} value={status.id}>
                        {status.name}
                    </option>
                    ))}
                </select>
                <button type="button" className='searchBtn' onClick={handleClick}>絞り込み</button>
            </div>
          </div>   
          <div className="body">
            <div className="adminTable1Wrapper">
              <div className="header">
                <div className="textWrapper">
                  <span className="text">{current_page}/{total_pages} ページ &nbsp;  全{total_count}件</span>
                </div>
                <div>
                </div>
              </div>
              <div className="body">
              
                <table className="table openTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>応募ID</th>
                      <th>求職者名</th>
                      <th>求人名</th>
                      <th>企業名</th>
                      <th>応募日時 </th>
                      <th style={{textAlign:'center'}}>応募ステータス</th>
                      <th>残日数</th>
                    </tr>
                  </thead>
                  <tbody>
                  {applicationList.length != 0 && applicationList ?
                  <>
                  {applicationList.map((applications: any, index: any) => (
                    <tr className='openTable'>
                      <td>{index +1}</td>
                      <td>{applications.id}</td>
                      <td><a href={'/admin/applicants_list/' + applications.applicant.id}>{ applications.applicant.family_name }{ applications.applicant.first_name }</a></td>
                      <td><a href={'/admin/openjob_list/' + applications.recruitment.id}>{ applications.recruitment.job_title}</a></td>
                      <td><a href={'/admin/companies/' + applications.company.id}>{ applications.company.name }</a></td>
                      <td >{ applications.date }</td>
                      {applications.status.name == '内定通知' ?
                      <td style={{textAlign:'center'}}><p  className='detailBtnOrange' >{ applications.status.name }</p></td>
                      : applications.status.name == '採用' ?
                      <td style={{textAlign:'center'}}><p  className='detailBtnBlue' >{ applications.status.name }</p></td>
                      
                      :applications.status.name == '不採用' ?
                      <td style={{textAlign:'center'}}><p  className='detailBtnRed' >{ applications.status.name }</p></td>
                      :
                      <td style={{textAlign:'center'}}><p  className='detailBtnBlack' >{ applications.status.name }</p></td>
                      }
                      <td>10日</td>

                      {/* {applications.status.name == '審査待ち' ?
                      <td><button  className='detailBtnBlue' >{ applications.status.name }</button></td>
                      : <></>}
                      {applications.status.name == '不採用' ?
                      <td><button  className='detailBtnRed' >{ applications.status.name }</button></td>
                      : <></>}
                       */}
                    </tr>    
                  ))}
                  </>
                  : <></>}
                  </tbody>
                </table>
                <div className="paginationWrapper">
                    <div className="icon"></div>
                    <ul className="pagination">
                        {current_page && current_page > 1 && (
                        <li className="arrow prev">
                            <a onClick={() => pageDecrement()}>
                            <img src="/assets/img/company/icon06.svg" alt="前へ" />
                            </a>
                        </li>
                        )}

                        {shaping_pagenation().map((n: any) => (
                        <li key={n} className={n === current_page ? 'active' : ''}>
                            <a onClick={() => updatePage(n)}>{n}</a>
                        </li>
                        ))}

                        {current_page && current_page < total_pages && (
                        <li className="arrow next">
                            <a onClick={() => pageIncrement()}>
                            <img src="/assets/img/company/icon07.svg" alt="次へ" />
                            </a>
                        </li>
                        )}
                    </ul>
                </div>
              </div>
            </div>
          </div> 
        </div>      
      </section>
    </main>
  );
};

export default AdminApplications;
